<template>
  <div>
    <el-card>
      <Combination :listName="title"></Combination>
      <div class="add-president-content">
        <div class="add-president-cnt">
          <el-form
            :model="employeeForm"
            status-icon
            :rules="rules"
            ref="groupRuleForm"
            label-width="150px"
            class="employee-formRule"
          >
            <el-form-item label="昵称-账号" prop="presidentName">
              <el-select
                v-model="employeeForm.presidentName"
                filterable
                placeholder="请选择"
                clearable
                class="add-select"
              >
                <el-option
                  v-for="(item, index) in options"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="公司名称" prop="companyName">
              <el-input v-model="employeeForm.companyName"></el-input>
            </el-form-item>
            <el-form-item label="公司法人身份ID" prop="companyAccount">
              <el-input v-model="employeeForm.companyAccount"></el-input>
            </el-form-item>
            <el-form-item label="企业信用代码">
              <el-input v-model="employeeForm.enterprise"></el-input>
            </el-form-item>
            <el-form-item label="备注">
              <el-input
                v-model="employeeForm.unionRemark"
                type="textarea"
                placeholder="请输入内容"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                @click="groupSubmitForm('groupRuleForm')"
                >添加</el-button
              >
              <el-button @click="groupResetForm('groupRuleForm')"
                >重置</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-card>
  </div>
</template>
<script>
import Combination from '@/components/Combination.vue';
import {
  addUnion,
  getEmployeeList,
  addGroup,
} from '@/service/moudules/platformApi';
import {
  checkPresidentName,
  checkCompanyName,
  checkCompanyAccount,
  checkEnterprise,
} from '@/util/proofread';
import { getCookie } from '@/util/userLoginFn';
export default {
  props: {
    types: {
      type: Number,
    },
    isWhos: {
      type: String,
    },
    isClean: {
      type: Boolean,
    },
  },
  watch: {
    isClean: {
      handler(val) {
        if (val) {
          this.groupResetForm('groupRuleForm');
        }
      },
      deep: true,
      immediate: true,
    },
  },
  components: { Combination },
  data() {
    return {
      addUnion,
      getCookie,
      employeeForm: {
        presidentName: null,
        companyName: null,
        companyAccount: null,
        enterprise: null,
        unionRemark: null,
      },
      rules: {
        presidentName: [
          { validator: checkPresidentName, trigger: 'change', required: true },
        ],
        companyName: [
          { validator: checkCompanyName, trigger: 'blur', required: true },
        ],
        companyAccount: [
          { validator: checkCompanyAccount, trigger: 'blur', required: true },
        ],
        // enterprise: [
        //   { validator: checkEnterprise, trigger: 'blur', required: true },
        // ],
      },
      options: [],
      title: '添加工会信息',
    };
  },
  created() {
    this.getEmployeeLists();
  },
  methods: {
    async getEmployeeLists() {
      try {
        let params = `?pageNo=1&pageSize=200&type=1`;

        const { code, msg, data } = await getEmployeeList(params);
        if (code !== 200 && data.records.length === 0) {
          this.$message({
            type: 'error',
            message: msg,
          });
          return false;
        }
        this.options = data.records.map((item, index) => {
          return {
            value: item.stuffId,
            label: item.stuffName,
          };
        });
      } catch (error) {
        console.log(error);
      }
    },
    groupResetForm(formName) {
      this.$refs[formName].resetFields();
    },
    groupSubmitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.addPresidentInfomations();
        } else {
          return false;
        }
      });
    },

    async addPresidentInfomations() {
      try {
        let {
          presidentName,
          companyName,
          companyAccount,
          enterprise,
          unionRemark,
        } = this.employeeForm;
        let params = {
          code: companyAccount,
          empNo: Number(enterprise),
          name: companyName,
          presidentId: presidentName,
          remark: unionRemark,
          sessionId: this.getCookie('sessionId'),
        };

        let { data, msg, code } = await addUnion(params);

        if (code !== 200 && msg !== 'success') {
          this.$message({
            type: 'error',
            message: msg,
          });
          return false;
        }

        this.$message({
          type: 'success',
          message: '添加信息成功',
        });
        this.$router.push({
          path: '/unionList',
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="less">
.employee-formRule {
  width: 500px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .add-select {
    width: 350px;
  }
}
.add-president-cnt {
  display: flex;
  justify-content: center;
}
</style>
